/* Text highlight */ 
.text-highlight {
    position: relative;
    z-index: 1; 
    span {
        display: inline-block;
        left:-3px;
        position: absolute;
        z-index: -1;
        width: calc(100% + 6px);
    }
}

/* Separator animation */ 
.separator-animation {
    width: 0 !important;
    @include prefix(transition, all 1s cubic-bezier(0.165, 0.84, 0.44, 1), webkit);
}
.shadow-in {
    .separator-animation {
        width: calc(100% + 6px) !important;
    }
}