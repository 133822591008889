// font
$alt-font: 'Plus Jakarta Sans', sans-serif;
$primary-font: 'Inter', sans-serif;

// color
$base-color: #2946f3;
$white: #ffffff;
$black: #000000;
$red: #dc3131;
$light-red: #feedec;
$green: #2ebb79;
$crusoe-green: #d39121;
$yellow: #ffea23;
$dark-gray: #232323; 
$medium-gray: #717580;
$extra-medium-gray: #e4e4e4;
$light-gray: #a8a8a8; 
$very-light-gray: #f7f7f7;
$light-medium-gray: #eaeaeb;
$charcoal-blue: #202329;
$slate-blue: #262b35; 
$medium-slate-blue: #374162;
$extra-medium-slate-blue: #23262d;
$dark-slate-blue: #1f232c;
$extra-very-slate-blue: #161620;
$tussock-yellow: #BC8947;
$aluminium-grey:#80858F;
$solitude-blue:#F0F0F6;
$golden-yellow:#fd961e;
$selago:#eaedff;
$white-ice:#d8f5ef;
$cornflower-blue:#4B66F7;
$jade:#00AF6B;
$orange: #ef991f;

// media query type variables
$media-max: max-width;
$media-min: min-width;

// social colors
$social-colors: (
behance: #1769ff,
behance-square: #1769ff,
blogger: #ff8000,
codepen: #0ebeff,
deviantart: #05cc47,
digg: #262626,
dribbble: #ea4c89,
delicious: #205cc0,
email: #7d7d7d,
facebook: #3b5998,
facebook-square: #3b5998,
flickr: #ff0084,
flipboard: #e12828,
github: #4078c0,
google: #dc4a38,
google-plus: #dd4b39,
instagram: #FE1F49,
jsfiddle: #0084FF,
linkedin: #0077b5,
livejournal: #00b0ea,
medium: #00ab6c,
meneame: #ff6400,
mailru: #168de2,
odnoklassniki: #d7772d,
paypal: #003087,
pinterest: #CB2027,
pinterest-p: #CB2027,
print: #222222,
reddit: #ff4500,
reddit-square: #ff4500,
rss: #f2890a,
skype: #00aff0,
slack: #6ecadc,
snapchat: #fffc00,
soundcloud: #ff8800,
spotify: #1db954,
sms: #ffbd00,
sharethis: #95D03A,
stack-overflow: #f48024,
stumbleupon: #eb4924,
telegram: #0088cc,
trello: #0079bf,
tumblr: #32506d,
twitch: #6441a5,
twitter: #00aced,
twitter-square: #00aced,
vimeo: #1ab7ea,
vine: #00bf8f,
vk: #4c6c91,
wordpress: #21759b,
weibo: #ff9933,
whatsapp: #25d366,
xing: #1a7576,
youtube: #ff0000,
youtube-play: #ff0000
);