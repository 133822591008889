/* Process step style 01 */
.process-step-style-01 {
    .progress-step-separator {
        position: absolute;
        top: 50%;
        left: calc(100% + 20px);
        @include prefix(transform, translateX(-50%), webkit);
    }
    .process-step-icon {
        position: relative;
        text-align: center;
        display: inline-block;
        vertical-align: middle;  
    }
    &:hover {
        .box-overlay {
            @include prefix(transform, scale(1.2), webkit); 
        }
        .number, .icon {
            color: var(--white);
        }
        .border-color-transparent-on-hover {
            border-color: transparent !important;
        }
    }
}

/* Process step style 02 */
.process-step-style-02 {
    .process-step-icon {
        overflow: hidden;
        position: relative;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        will-change: transform;
    }
    .progress-step-separator {
        position: absolute;
        top: 50%;
        left: calc(100% + 15px);
        @include prefix(transform, translateX(-50%), webkit);
    }
    &:hover {
        .process-step-icon {
            @include prefix(transform, scale(1.1), webkit);
            will-change: transform;
        }
    }
}

/* Process step style 03 */
.process-step-style-03 {
    &.hover-box * {
        will-change: auto;
        vertical-align: top;
    }
    .process-step-icon-box {
        .progress-step-separator {
            position: absolute;
            top: 50%;
            left: calc(100% + 15px);
            @include prefix(transform, translateX(-50%), webkit);
            @include prefix(transition, 0.4s, webkit);
        }
        .number {
            opacity: 0;
            visibility: hidden; 
            @include prefix(transition, 0.4s, webkit);
            top: 55%;
        }
    }
    &:hover {
        .process-step-icon-box {
            .box-overlay {
                opacity: 0.9;
            }
            .number {
                opacity: 1;
                visibility: visible; 
                top: 50%;
            }
            img{
                @include prefix(transform, scale(1.1), webkit);
            }
            .progress-image{
                @include prefix(transform, scale(1.1), webkit);
            }
        }
    }
}

/* Process step style 04 */
.process-step-style-04 {
    .process-step-icon-box {
        will-change: transform;
        .progress-step-separator {
            position: absolute;
            top: 50%;
            left: calc(100% + 15px);
            @include prefix(transform, translateX(-50%), webkit);
        }
    }
    &:hover {
        .process-step-icon-box {
            .number, .icon {
                @include prefix(transform, scale(1.3), webkit);
            }
        }
    }
}

/* Process step style 05 */
.process-step-style-05 {
    .process-step-item {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        align-items: stretch;
        justify-content: flex-start;
        text-align: left;
    }
    .process-content {
        overflow: hidden;
        flex: 1;
        max-width: 100%; 
    }
    .progress-step-separator { 
        position: absolute; 
        bottom: 15px;
        width: 1px;
        left:50%; 
        height: calc(100% - 100px);
    }
    .process-step-icon{
        will-change: transform;
        @include prefix(transition, all 350ms cubic-bezier(0.165, 0.84, 0.44, 1.7), webkit);
    }
    &:hover {
        .process-step-icon{
            @include prefix(transform, scale(1.1), webkit); 
        }
        .number, .icon {
            color: var(--white);
        }
    }
}

/* Process step style 07 */
.process-step-style-06 {
    .process-step-icon-box {
        .progress-step-separator {
            position: absolute;
            top: 50%;
            left: 0;
        }
    }
    &:hover {
        .process-step-icon-box {
            .step-box {
                @include prefix(transform, scale(1.3), webkit);
            }
        }
    }
}

/* Process step style 08 */
.process-step-style-08 {
    .process-step-item{
        @include prefix(transform, translateY(0), webkit);
        @include prefix(transition, all 350ms cubic-bezier(.23,1,.32,1), webkit);
        &:hover{
            @include prefix(transform, translateY(-8px), webkit);
        }
    }
}

/* Process step style 09 */
.process-step-style-09 {
    .progress-right-icon {
        position: absolute; 
        top: 50%;
        left: 100%;
        @include prefix(transform, translateY(-50%), webkit);
    }
    .process-step-icon {
        position: relative;
        text-align: center;
        display: inline-block;
        vertical-align: middle;  
    }
    &:hover {
        .box-overlay {
            @include prefix(transform, scale(1.2), webkit); 
        }
        .border-color-transparent-on-hover {
            border-color: transparent !important;
        }
    }
}

/* Process step style 10 */
.process-step-style-10 {
    .process-step-icon-box {
        @include prefix(transition, all 650ms cubic-bezier(.23,1,.32,1), webkit);
    }
    &:hover {
        .process-step-icon-box {
            @include prefix(transform, translateX(20px) scale(1.1), webkit);
        }
    }
    &.hover-box {
        .hover-content {
            opacity: 0;
            position: absolute;
            left: 0;
            bottom: -15px;
        }
        &:hover {
            .hover-content {
                opacity:1;
                bottom: -5px;
            }
        }
    }
}

/* Process step style 11 */
.process-step-style-11 {
    .process-step-icon-wrap {
        z-index: 1;
        background-color: var(--base-color);
        background-size:4px 4px;
        @include prefix(transition, all 650ms cubic-bezier(.23,1,.32,1), webkit);
    }
    &:hover {
        .process-step-icon-wrap {
            background-color: var(--dark-gray);
            background-image: none;
            @include prefix(transform, scale(1.1), webkit);
            @include prefix(transition, all 650ms cubic-bezier(.23,1,.32,1), webkit);
        }
    }
}