/* Columns */
.show-grid {
    & [class^="col-"] {
        background-color: #f5f5f5;
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
    }
}