/* First letter */ 
.first-letter {
    float: left;
    font-size: 4.5rem;
    line-height: 4.5rem;
    font-weight: 600;
    text-align: center;
    margin: 0 25px 0 0;
}

/* First letter block */ 
.first-letter-block {
    font-size: 4.5rem;
    line-height: 100px;
    font-weight: 600;
    width: 100px;
    height: 100px;
    margin: 8px 35px 0 0;
}

/* First letter round */ 
.first-letter-round {
    @include prefix(border-radius, 50%, webkit);
    font-size: 3.5rem;
}

/* First letter big */ 
.first-letter-big {
    float: left;
    font-size: 9rem;
    line-height: 9rem;
    padding: 0;
    text-align: center;
    position: relative;
}

/* First letter small */ 
.first-letter-small {
    font-size: 3.438rem;
    line-height: 4rem;
    font-weight: 600;
    width: 65px;
    height: 65px;
    margin: 8px 30px 0 0;   
}