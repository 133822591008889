/* Progress bar */
.progress {
    position: relative;
    overflow: visible;
    .progress-bar {
        width: 0px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: visible;
        @include prefix(transition, all 1s cubic-bezier(0.50, 1, 0.5, 1), webkit);
        &.appear {
            .progress-bar-percent {
                opacity: 1;
            }
        }
    }
}
.progress-bar-percent {
    opacity: 0;
}

/* Progress bar style 01 */
.progress-bar-style-01 {
    .progress {
        height: 4px;
        border-radius: 0;
        .progress-bar-title {
            position: absolute;
            bottom: 10px;
        }
        .progress-bar-percent {
            position: absolute;
            bottom: 17px;
            right: -20px;
            padding: 7px;
            min-width: 40px;
            min-height: 26px;
            border-radius: 3px;
            &:after {
                content: '';
                top: 100%;
                left: 50%;
                height: 0;
                width: 0;
                z-index: 9;
                margin-left: -4px;
                border-width: 4px;
                position: absolute;
                pointer-events: none;
                border: solid transparent;
                border-top-color: #232323;
            }
        }
        .progress-bar {
            height: 100%;
        }
    }
}

/* Progress bar style 02 */
.progress-bar-style-02 {
    .progress {
        height: 30px;
        overflow: hidden;
        .progress-bar-percent {
            position: absolute;
            top: 9px;
            right: 20px;        
            min-width: 26px;
        }
        .progress-bar-title {
            position: absolute;
            top: 9px;
            left: 20px;
        }
        .progress-bar {
            width: 0px;
            height: 100%;
            position: absolute;
        }
    }
}

/* Progress bar style 03 */
.progress-bar-style-03 {
    .progress {
        height: 4px;
        .progress-bar-title {
            position: absolute;
            bottom: 14px;
        }
        .progress-bar {
            height: 100%;
        }        
        .progress-bar-percent {
            position: absolute;
            bottom: 14px;
            right: 0;
            opacity: 1;
        }
    }
}

/* Progress bar style 04 */
.progress-bar-style-04 {
    .progress {
        height: 8px;
        border-radius: 6px;
        .progress-bar-title {
            position: absolute;
            bottom: 15px;
        }
        .progress-bar {
            height: 100%;
            border-radius: 6px;
        }
        .progress-bar-percent {
            position: absolute;
            bottom: 15px;
            right: 0px;
        }
    }
}