/* Feature box style */
.feature-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    position: relative;
    z-index: 1;
    .feature-box-icon { 
        i:not([class*="text-gradient-"]) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;   
        }
    }
    &.feature-box-left-icon, &.feature-box-left-icon-middle {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: stretch !important; 
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        text-align: left;
        .feature-box-icon {
            margin-right: 30px; 
        }
        .feature-box-content {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
        }
        .content-slide-up {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        } 
    }
    &.feature-box-left-icon-middle {
        -webkit-flex-flow: row wrap; 
        -ms-flex-flow: row wrap; 
        -webkit-box-align: center !important; 
        -ms-flex-align: center !important; 
        align-items: center !important; 
        -webkit-box-align: center; 
        -ms-flex-pack: center; 
        justify-content: flex-start; 
        text-align: left;
        .content-slide-up {
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
        }
    }
    &.text-start {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: start;
    }
    &.text-end {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
    .feature-box-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
    } 
    .content-slide-up {
        will-change: transform;
        @include prefix(transform, translateY(30px), webkit);
        .text-visible {
            opacity: 0;
        } 
    }
    .border-hover {
        left:0;
        bottom: -1px;
        width: calc(100% + 0px);
        height: 5px;
        position: absolute;
        @include prefix(transform, scaleX(0), webkit);
        @include prefix(transition, transform .5s ease, webkit);
        will-change: transform;
    }
    .feature-box-bg-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -9;
        opacity: 0;
        @include prefix(transform, scale(0.8), webkit);
        @include prefix(transition, 0.5s, webkit);
    }
    .feature-box-icon-rounded {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        overflow: hidden;
        @include prefix(transition, 0.3s, webkit);
        .feature-box-icon-hover {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
        i {
            position: relative;
            z-index: 1;
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        }
    }
    .box-move-bottom-top {
        @include prefix(transform, translateY(28px), webkit);
        @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
    }
    .move-bottom-top { 
        @include prefix(transform, translateY(20px), webkit);
        @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        opacity:0; 
    }
    &:hover {
        .box-move-bottom-top {
            @include prefix(transform, translateY(0px), webkit);
            .move-bottom-top { 
                @include prefix(transform, translateY(0px), webkit);
                opacity:1; 
            }
        }
        .border-hover {
            @include prefix(transform, scaleX(1), webkit);
        }
        .feature-box-overlay {
            opacity: 1;
        }
        .content-slide-up {
            @include prefix(transform, translateY(0), webkit);
            .text-visible {
                opacity: 1;
            }
            &.content-scale {
                .feature-box-overlay {
                    @include prefix(transform, scale(1.1), webkit);
                }
            }
        }
        .feature-box-bg-overlay {
            opacity: 1;
            @include prefix(transform, scale(1), webkit);
        } 
        .feature-box-icon-rounded {
            &.border-color-transparent-on-hover {
                border-color: transparent !important;
            }
            .feature-box-icon-hover {
                opacity: 1;
            }
        }
        .feature-box-content {
            .underline-on-hover {
                color: var(--base-color);
                &:after {
                    opacity: 1;
                    @include prefix(transform, translateY(0), webkit);
                }
            }
        } 
        .dark-hover {
            i, span {
                color: #fff;
            }
            [class*="text-gradient-"] {
                color: var(--white);
                background-image: none;
                -webkit-background-clip: inherit;
                -webkit-text-fill-color: var(--white);
                -webkit-text-fill-color: var(--white);

            }
        }
    }
    &.border-bottom {
        .feature-box-overlay {
            height: calc(100% + 1px); 
        }
    }
    &.border-top {
        .feature-box-overlay {
            height: calc(100% + 1px); 
        }
    }
}

/* Icon with text style 03 */ 
.icon-with-text-style-03 * {
    word-break: initial;
}

/* Icon with text style 05 */ 
.icon-with-text-style-05 {
    .feature-box {
        height: 100%;
    }
}

/* Icon with text style 07 */ 
.icon-with-text-style-07 {
    .hover-box {
        &:hover {
            .feature-box-title{
                left: -25%;
                &.text-outline-color-dark-gray {
                    -webkit-text-stroke-color: var(--base-color);
                    opacity: 1; 
                }
            }
        }
    }
    .feature-box-title{
        position: absolute;
        top: 30px;
        left: 50%;
        word-break: normal;
        width: 100%; 
        @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
    }
}

/* Icon with text style 10 */ 
.icon-with-text-style-10 {
    .feature-box-icon {
        &:before {
            width: 100%;
            height: 50%;
            background-color:var(--base-color);
            opacity: 0.12;
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            top: 0; 
            -webkit-transform: rotate(-40deg);
            -ms-transform: rotate(-40deg);
            transform: rotate(-40deg);
            -webkit-transform-origin: bottom center;
            -ms-transform-origin: bottom center;
            transform-origin: bottom center;
            @include prefix(transition, 0.4s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        } 
    }
    &:nth-child(even) {
        .feature-box-icon {
            &:before {
                -webkit-transform: rotate(140deg);
                -ms-transform: rotate(140deg);
                transform: rotate(140deg);
            } 
        }
    }
    &:hover {
        .feature-box-icon { 
            @include prefix(transform, scale(1.1), webkit);
            @include prefix(transition, all 300ms cubic-bezier(0.37, 0.80, 0.63, 1.2), webkit);
            i {
                color: var(--white);
            }
            &:before {
                height: 100%;  
                -webkit-transform-origin:  center;
                -ms-transform-origin:  center;
                transform-origin:  center;
                opacity: 1;
            }
        }
    }
}

/* Icon with text style 11 */ 
.icon-with-text-style-11 {
    @include prefix(transform, translateY(0px), webkit);
    @include prefix(transition, all 0.3s linear, webkit);
    .text-box {
        opacity: 1;  
    }
    .text-box-hover {
        position: absolute;
        top: 0;
        left: 0; 
        opacity: 0; 
        @include prefix(transition, all 0.3s linear, webkit);
    }
    &:hover {
        @include prefix(transform, translateY(-15px), webkit);
        .text-box-hover {
            opacity: 1; 
        }
        .text-box {
            opacity: 0; 
        }
    }
}