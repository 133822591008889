/* Clients style 01 */ 
.clients-style-01 {
    .client-image {
        &:hover {
            > img {
                @include prefix(transform, scale(1.1), webkit);
                -webkit-filter: blur(3px);
                filter: blur(3px);
            }
        }
    }
}

/* Clients style 02 */
.clients-style-02 {
    .swiper-slide {
        @include prefix(transition, all 350ms ease-in-out, webkit);
        &:hover {
            -webkit-filter: grayscale(1);
            filter: grayscale(1);
        }
    }
    .swiper-button-prev {
        left: -110px;
    }
    .swiper-button-next {
        right: -110px;
    }
}

/* Clients style 03 */
.clients-style-03 {
    .client-box {
        @include prefix(transition, all 350ms cubic-bezier(0.12, 0, 0.39, 0), webkit);
        will-change: transform;
        &:hover { 
            @include prefix(transform, scale(1.1), webkit);
        }
    }
}

/* Clients style 04 */
.clients-style-04 {
    .client-box {
        &:hover {
            opacity: 0.5;
        }
    }
}

/* Clients style 05 */
.clients-style-05 {
    .client-box {
        position: relative; 
        @include prefix(transition, 0.3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        img {
            z-index: 2;
            position: relative;
        }
        &:hover {
            opacity: 0.5; 
            @include prefix(transform, translateY(-8px), webkit);
        } 
    }
}

/* Clients style 06 */
.clients-style-06 {
    .client-box {
        @include prefix(transition, all 0.3s cubic-bezier(0.12, 0, 0.39, 0), webkit);
        img {
            max-width: inherit;
        }
    }
    &:hover {
        .client-box {
            opacity: .4;
            &:hover {
                opacity: 1;
            }
        }
    }
}

/* Clients style 07 */
.clients-style-07 {
    .client-box {
        .client-info {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            .client-content {
                position: absolute;
                bottom: 0;
                right: 0;
                @include prefix(transition, all 0.5s cubic-bezier(0.77, 0, 0.175, 1), webkit);
                overflow: hidden;
                .client-link {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    left: 0;
                    right: 0;
                }
            }
            &:hover {
                .client-content {
                    margin: 0;
                    width: 100% !important;
                    height: 100% !important;
                    border-radius: 0;
                    .client-icon {
                        opacity: 0;
                        visibility: hidden;
                    }
                    .client-link {
                        opacity: 1;
                        @include prefix(transition-delay, 0.5s, webkit);
                    }
                    .box-overlay {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}

/* Clients style 08 */
.clients-style-08 {
    .client-box {
        @include prefix(transition, all 0.3s ease, webkit);
        opacity: .5;
        &:hover {
            opacity: 1;
        }
    }
} 