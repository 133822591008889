/* ===================================
   10. Header
====================================== */
header { 
    .header-top-bar {
        [class*="col-"] {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-item-align: stretch;
            -ms-grid-row-align: stretch;
            align-self: stretch;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            &.text-end {
                justify-content: flex-end!important;
            }
            .header-icon {
                -ms-flex-item-align: stretch;
                -ms-grid-row-align: stretch;
                align-self: stretch;
            }
        }
    }
    .navbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 99;
        [class*="col-"] {
            padding: 0 15px; 
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-item-align: stretch;
            -ms-grid-row-align: stretch;
            align-self: stretch;
            &.text-end {
                justify-content: flex-end!important;
            }
            .navbar-nav {
                -ms-flex-item-align: stretch;
                -ms-grid-row-align: stretch;
                align-self: stretch;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .nav-item {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-item-align: stretch;
                    -ms-grid-row-align: stretch;
                    align-self: stretch;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    a {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-item-align: stretch;
                        -ms-grid-row-align: stretch;
                        align-self: stretch;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                }
            } 
        }
    } 
    .container-fluid {
        padding-left: 45px;
        padding-right: 45px;
    }

    // Header sticky
    &.sticky { 
        .navbar-brand { 
            padding: 20px 0;
        }
        .navbar {
            @include prefix(transform, translateY(-100%), webkit); 
            &.sticky-header {
                @include prefix(transform, translateY(0), webkit); 
            }
            &.fixed-header {
                @include prefix(transform, translateY(0), webkit);   
            }
        } 
        .header-light:not(.disable-fixed) {
            box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);   
        }
        
        // Header sticky active
        &.sticky-active {   
            .header-reverse { 
                @include prefix(transform, translateY(0), webkit);
                border-color: transparent !important; 
                &.glass-effect {
                    -webkit-backdrop-filter: blur(5px);
                    backdrop-filter: blur(5px);
                    background-color: rgba(255,255,255,.85) !important;
                }
                &.header-dark {
                    &.glass-effect {
                        background-color: rgba(35,35,35,.7) !important;
                    } 
                }
            }
            .navbar-brand {
                .alt-logo {
                    visibility: visible;
                    opacity: 1;
                    width: auto; 
                }
                .default-logo {
                    visibility: hidden;
                    opacity: 0;
                    width: 0;
                } 
            }
            
            // Header dark style sticky active
            [data-header-hover="dark"] {
                background-color: var(--dark-gray) !important;  
                .navbar-nav {
                    .nav-item {
                        &:hover {
                            .nav-link {
                                color: var(--white); 
                                opacity: 0.6;
                            }
                        }
                    }
                    .nav-link {
                        color: var(--white);
                    }
                }
                .icon { 
                    > a {
                        color: var(--white);
                        &:hover {
                            color: var(--white);
                            opacity: 0.6;
                        }
                    }
                    .push-button {
                        span {
                            background-color:var(--white);
                        }
                    }
                    .header-language, .header-cart {
                        > a {
                            color: var(--white);
                            &:hover {
                                color: var(--white);
                            }
                        }
                    }
                }
                .header-search-style {
                    i {
                        color:var(--white);
                    }
                }
                .header-language-icon {
                    .header-language {
                        .language-dropdown {
                            background-color: var(--dark-gray);
                            a {
                                color: var(--light-gray);
                                &:hover {
                                    color:var(--white);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
            
            // Header light style sticky active
            [data-header-hover="light"] {
                background-color: var(--white) !important;
                box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1); 
                .navbar-nav {
                    .nav-link {
                        color: var(--dark-gray);
                    }
                }
                .icon {
                    > a {
                        color: var(--dark-gray);
                        &:hover {
                            color: var(--medium-gray);
                            opacity: 1;
                        }
                    }
                    .push-button {
                        span {
                            background-color: var(--dark-gray);
                        }
                    }
                    .header-language, .header-cart {
                        > a {
                            color: var(--dark-gray);
                            &:hover {
                                color: var(--medium-gray);
                                opacity: 1;
                            }
                        }
                    }
                }
                .header-search-style {
                    i {
                        color: var(--dark-gray);
                    }
                }
                .header-language-icon {
                    .language-dropdown {
                        li {
                            a {
                                color:var(--medium-gray);
                                &:hover {
                                    color: var(--dark-gray);
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
                .widget-text {
                    color:var(--dark-gray);
                    a {
                        color:var(--dark-gray);
                    }
                }
                &.disable-fixed {
                    &.header-demo {
                        background-color: transparent !important;  
                    }
                }
            }
        }
        .header-icon {
            .push-button {
                padding: 0;
            }
        }
        .center-logo {
            .navbar-nav {
                .nav-link {
                    padding: 26px 20px;
                }
            }
        }
        .top-logo {
            .navbar-brand {
                padding: 15px 0;
            }
            &.fixed-header, &.disable-fixed {
                .navbar-brand {
                    padding: 22px 0;
                }
            }
            &.responsive-sticky {
                .navbar-nav .nav-link {
                    padding-top: 18px !important;
                    padding-bottom: 18px !important;
                }
                .header-icon {
                    .header-cart-icon {
                        .header-cart {
                            a {
                                i {
                                    padding-top: 22px;
                                    padding-bottom: 22px;
                                }
                            }
                        }
                    }
                    .header-language-icon {
                        .header-cart {
                            > a {
                                i {
                                    padding-top: 22px;
                                    padding-bottom: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .mini-header {
            .header-icon {
                .header-language {
                    a {
                        i {
                            padding-top: 16px;
                            padding-bottom: 16px;
                        }
                    }
                }
                .header-cart-icon {
                    .header-cart {
                        a {
                            i {
                                padding-top: 16px;
                                padding-bottom: 16px;
                            }
                        }
                    }
                }
            }
        }
        .fixed-header, .disable-fixed {
            .navbar-brand {
                padding: 30px 0;
            } 
        }
    }
    .navbar {
        @include prefix(transition, 0.3s, webkit);
        &.mini-header {
            .navbar-brand {
                padding:20px 0;
                img {
                    max-height: 22px;
                }
            }
        }
    }

    // Header logo
    .navbar-brand {
        margin-right:0;
        vertical-align: middle;
        padding: 30px 0;
        display: inline-block;
        font-size:0;
        .default-logo {
            visibility: visible;
            opacity: 1;
            width: auto;
        }
        .alt-logo, .mobile-logo {
            visibility: hidden;
            opacity: 0;
            width: 0;
        }
        img {
            max-height: 46px;
        }
    }

    // Header top bar
    .header-top-bar {
        position: fixed;
        top:0;
        right: 0;
        width: 100%;
        left: 0;
        z-index: 100;
        @include prefix(transition, 0.3s, webkit);
        + .navbar {
            @include prefix(transition, 0.3s, webkit);
            top: 40px;
            &.disable-fixed {
                top: 0;
            }
        }
    }
    .header-top-bar {
        .widget {
            display: inline-block;
            i {
                padding-right: 5px;
            }
        }
        .header-icon {
            .header-social-icon {
                top:0;
                ul {
                    list-style:none;
                    padding:0;
                    margin: 0;
                    li{
                        display: inline-block;
                        margin: 0 15px 0 0;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
                a {
                    font-size: 12px;
                }
            }
            .header-social-text{
                top:0;
                ul {
                    list-style:none;
                    padding:0;
                    margin: 0;
                    li{
                        display: inline-block;
                        margin: 0 30px 0 0;
                        &:last-child{
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    // Left navigation
    .left-nav {
        .navbar-nav {
            &:before {
                height: 20px;
                width: 1px;
                background-color: var(--medium-gray);
                opacity: 0.5;
                content: ""; 
                @include prefix(transform, translate(-7px, 0px), webkit);
                @include prefix(transition, 0.3s, webkit);
            }
        }
    }

    // Disable fixed header
    .disable-fixed {
        position: absolute;
    }

    // Responsive sticky header
    &.sticky {
        .responsive-sticky {
            @include prefix(transform, translateY(0), webkit);
        }
        .navbar {
            &.disable-fixed {
                @include prefix(transform, translateY(0), webkit);
                box-shadow: none;
            }
        }
    }
}

/* Navbar */
.navbar {
    padding:0;
    .navbar-nav {
        .nav-item {
            > i {
                display: none;
            }
            &:hover, &.active {
                .nav-link {
                    opacity: 0.6;
                }
            }
            .nav-link {
                &.active {
                    opacity: 0.6;
                }
            }
        }
        .nav-link {
            font-size: 16px;
            line-height: 20px;
            padding: 10px 20px;
            position: relative;
            font-weight:500;
            opacity: 1;
            word-break: normal;
            @include prefix(transition, 0.3s, webkit);
            color: var(--white);
            .label {
                display: inline-block;
                padding:1px 11px;
                font-size: 12px;
                margin-left: 8px;
                margin-top: 0px;
                vertical-align: middle;
            }
        }
        .dropdown {
            &.submenu {
                position: inherit;
            }
            .dropdown-menu {
                margin: 0;
                padding:60px 0 65px;
                border: 0;
                top: 100%;
                background-color: var(--white);
                box-shadow: 0 45px 45px 0 rgba(0, 0, 0, 0.05);
                -webkit-animation: hide-animation .5s;
                animation: hide-animation .5s;
                @include prefix(border-radius, 0, webkit);
                li {
                    &:last-child {
                        >  a {
                            padding-bottom: 0;
                        }
                    }
                    &.active {
                        > a {
                            color: var(--dark-gray);
                        }
                    }
                    a {
                        &:hover { 
                            padding-left: 3px;
                        }
                    }
                }
                a {
                    color: var(--medium-gray);
                    font-size: 15px;
                    line-height: 24px;
                    font-weight: 400;
                    padding-bottom: 10px;
                    display: block;
                    &:hover {
                        color: var(--dark-gray); 
                    }
                    i {
                        width: 25px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 7px;
                        text-align: center;
                    }
                    .label {
                        display: inline-block;
                        padding:5px 10px;
                        font-size: 10px;
                        line-height: initial;
                        margin-left: 8px;
                        margin-top: 0px;
                        vertical-align: middle;
                        font-weight: 700;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
            }
            &.open {
                .dropdown-menu {
                    display: block;
                    -webkit-animation: show-animation .5s;
                    animation: show-animation .5s;
                }
            }
        }
        .submenu-content {
            width: 100%;
            border-top: 1px solid #e7e7e7 !important;
            left:0;
            ul {
                padding:0 30px 0 0;
                list-style: none;
                &:nth-child(4n+1) {
                    clear: both;
                }
                .sub-title {
                    color: var(--dark-gray);
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                &:last-child {
                    &:before {
                        display: none;
                    }
                }
            }
            .mega-menu {
                width: 1190px;
            }
        }
        .simple-dropdown {
            .dropdown-menu {
                display: none;
                width: 245px;
                padding: 35px 0;
                @include prefix(border-radius, 0, webkit);
                box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
                li {
                    padding:0 45px 0 40px;
                    a {
                        padding-bottom:12px;
                    }
                }
                .dropdown {
                    position: relative;
                    i {
                        position: absolute;
                        right: 35px;
                        top: 6px;
                        width: auto;
                        font-size: 13px;
                    }
                    &.open{
                        > a {
                            color: var(--dark-gray);
                        }
                        > .dropdown-menu {
                            display: block;
                        }
                    }
                    &.menu-left {
                        .dropdown-menu {
                            left:calc( -100% + -1px );
                        }
                    }
                    .dropdown-menu {
                        -webkit-animation: none;
                        animation: none;
                    }
                }
                .dropdown-menu {
                    display: none;
                    position: absolute;
                    left: calc( 100% + 1px );
                    top: -35px;
                }
            }
        }
        .dropdown {
            // Dropdown with icon style02
            &.dropdown-with-icon-style02 {
                .dropdown-menu {
                    border-radius: 5px;
                    padding: 0;
                    overflow: hidden;
                    width: 260px;
                    li {
                        padding:0;
                        a {
                            font-size: 15px;
                            line-height: 26px;
                            border-bottom: 1px solid rgba(23,23,23,.1);
                            padding: 17px 20px;
                            i {
                                font-size: 19px;
                            }
                            img {
                                width: 35px;
                                margin-right: 15px;
                            }
                        }
                        &:last-child {
                            a {
                                border-bottom: 0;
                            }

                        }
                    }
                }
            }

            // Dropdown with icon
            &.dropdown-with-icon {
                .dropdown-menu {
                    box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 5px;
                    width: 310px;
                    padding: 20px 35px;
                    li {
                        justify-content: center;
                        display: flex;
                        border-bottom: 0;
                        padding: 0;
                        &:first-child {
                            a {
                                border-top: 0;
                            }
                        }
                        a {
                            margin: 0;
                            border-top: 1px solid rgba(23,23,23,0.1);
                            padding: 15px 30px 15px 0;
                            font-size: 16px;
                            line-height: 28px;
                            font-weight: 500;
                            width: 100%;
                            position:relative;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            i {
                                width: 40px;
                                font-size: 36px;
                                margin-right: 15px;
                                color: var(--base-color);
                                flex-shrink: 0;
                                text-align: left;
                            }
                            span{
                                color: var(--dark-gray);
                                font-weight: 600;
                            }
                            p {
                                margin: 0;
                                font-size: 13px;
                                color: var(--medium-gray);
                            }
                            img {
                                border-radius: 100%;
                                width: 45px;
                                margin-right: 15px;
                            }
                            &:before {
                                content: "\F135";
                                color: var(--black);
                                font-family: bootstrap-icons;
                                font-weight: 400;
                                font-size: 23px;
                                position: absolute;
                                right: 6px;
                                opacity: 0;
                                @include prefix(transition, all 300ms cubic-bezier(0.61, 1, 0.88, 1), webkit);
                            }
                            &:hover {
                                &:before {
                                    right: 0px;
                                    opacity: 1;
                                }
                            }
                            &.btn {
                                padding: 15px 20px;
                                justify-content: center;
                                margin-top: 10px;
                                line-height: normal;
                                border-top: 0;
                                font-size: 14px;
                                margin-bottom: 5px;
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Header hover submenu dark */
[data-header-hover="dark"] {
    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                li {
                    &.active {
                        > a {
                            color: var(--white);
                        }
                    }
                }
            }
            &.dropdown-with-icon-style02 {
                .dropdown-menu {
                    li {
                        a {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        }
                    }
                }
            }
            &.dropdown-with-icon {
                .dropdown-menu {
                    li {
                        a {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                            span {
                                color: var(--white) !important;
                            }
                            &:before {
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
        .simple-dropdown, .dropdown-with-icon-style02, .dropdown-with-icon {
            .dropdown-menu {
                background-color: var(--dark-gray);
                a {
                    color:var(--light-gray);
                    &:hover {
                        color: var(--white);
                    }
                }
                .dropdown:hover {
                    > a {
                        color: var(--white);
                    }
                }
            }
        }
    }
    .header-cart-icon {
        .header-cart {
            .cart-item-list {
                background-color: var(--dark-gray);
                .cart-item {
                    border-color:#3e3e3e;
                    &.cart-total {
                        background-color: var(--black);
                        span {
                            color: var(--white);
                        }
                    }
                    .product-detail {
                        a {
                            color: var(--medium-gray);
                            &:hover {
                                color: var(--white);
                            }
                        }
                    }
                    .close {
                        color: var(--white); 
                    }
                }
            }
        }
    }
    .header-language-icon {
        .header-language {
            .language-dropdown {
                background-color: var(--dark-gray);
                a {
                    color: var(--light-gray);
                    &:hover {
                        color:var(--white);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.submenu-dark { 
    background-color: var(--dark-gray) !important; 
    .navbar-brand { 
        .alt-logo {
            visibility: visible;
            opacity: 1;
            width: auto; 
        }
        .default-logo {
            visibility: hidden;
            opacity: 0;
            width: 0; 
        } 
    }
    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                background-color: var(--dark-gray);
                a {
                    color:var(--light-gray);
                    &:hover {
                        color: var(--white);
                    }
                }
                li {
                    &.active {
                        > a {
                            color: var(--white);  
                        }
                    }
                }
            } 
        }
        .submenu-content {
            border-color:#424242 !important;
            ul {
                .sub-title {
                    color: var(--white);
                }
                &:before {
                    background-color: var(--medium-gray);
                }
            }
        }
        .simple-dropdown {
            .dropdown-menu {
                background-color: var(--dark-gray);
                a {
                    color:var(--light-gray);
                    &:hover {
                        color: var(--white);
                    }
                } 
                .dropdown:hover {
                    > a {
                        color: var(--white);
                    }
                } 
            }
        }
    }
    .header-cart-icon { 
        .header-cart {
            .cart-item-list {
                background-color: var(--dark-gray);
                .cart-item {
                    border-color:#3e3e3e;
                    &.cart-total {
                        background-color: var(--black);
                        span {
                            color: var(--white);
                        }
                    }
                    .product-detail {
                        a {
                            color: var(--medium-gray);
                            &:hover {
                                color: var(--white);
                            }
                        } 
                    }
                    .close {
                        color: var(--white); 
                    }
                }
            }
        }
    }  
    .header-cart-icon {
        .header-language {
            .language-dropdown { 
                background-color: var(--dark-gray);
                a {
                    color: var(--light-gray);
                    &:hover {
                        color:var(--white);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/* Header hover submenu light */
.submenu-light { 
    background-color: var(--white) !important; 
    .navbar-brand { 
        .alt-logo {
            visibility: visible;
            opacity: 1;
            width: auto; 
        }
        .default-logo {
            visibility: hidden;
            opacity: 0;
            width: 0; 
        } 
    }
    .navbar-nav {
        .nav-link {
            color: var(--dark-gray); 
        }
    }
    .header-icon {
        .icon { 
            > a {
                color: var(--dark-gray);
                &:hover {
                    color: var(--medium-gray);   
                }
            }
            .push-button {
                span {
                    background-color: var(--dark-gray);
                }
            }
            .header-language, .header-cart {
                > a {
                    color: var(--dark-gray);  
                    &:hover {
                        color: var(--medium-gray); 
                    } 
                }
            }
        }  
    }
    .menu-item-list {
        .nav-link {
            color: var(--dark-gray);
            &:hover {
                color: var(--dark-gray);
                opacity: 0.6;  
            }
        }
        .sub-menu {
            a {
                color: var(--dark-gray);
                opacity: 0.6;  
            }
        }
        .menu-item {
            .toggle {
                color: var(--dark-gray);  
            }
        }  
    }  
    .widget-text {
        color:var(--dark-gray);
        a {
            color:var(--dark-gray);
        } 
    } 
}

/* Header dark */
.header-dark {  
    .navbar-nav {
        .dropdown {
            .dropdown-menu {
                background-color: var(--dark-gray);
                a {
                    color:var(--light-gray);
                    &:hover {
                        color: var(--white);
                    }
                }
                li {
                    &.active {
                        > a {
                            color: var(--white);  
                        }
                    }
                }
            }
            // Dropdown with icon style02
            &.dropdown-with-icon-style02 {
                .dropdown-menu {
                    li {
                        a {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        }
                        &:last-child {
                            a {
                                border-bottom: 0;
                            }

                        }
                    }
                }
            }

            // Dropdown with icon 
            &.dropdown-with-icon {
                .dropdown-menu {
                    li {
                        a {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                            span{
                                color: var(--white);
                            }
                            p {
                                color: var(--light-gray);
                            }
                            &:before {
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
        .submenu-content {
            border-color:#424242 !important;
            ul {
                .sub-title {
                    color: var(--white);
                }
                &:before {
                    background-color: var(--medium-gray);
                }
            }
        }
        .simple-dropdown {
            .dropdown-menu {
                background-color: var(--dark-gray);
                a {
                    color:var(--light-gray);
                    &:hover {
                        color: var(--white);
                    }
                }
                .dropdown {
                    &.open {
                        > a {
                            color: var(--white);
                        }   
                    }
                }
                .dropdown {
                    &:hover {
                        > a {
                            color: var(--white);
                        }
                    }
                } 
            }
        }
    }
    .header-cart-icon { 
        .header-cart {
            .cart-item-list {
                background-color: var(--dark-gray);
                .cart-item {
                    border-color:#3e3e3e;
                    &.cart-total {
                        background-color: var(--black);
                        span {
                            color: var(--white);
                        }
                    }
                    .product-detail {
                        a {
                            color: var(--medium-gray);
                            &:hover {
                                color: var(--white);
                            }
                        } 
                    }
                    .close {
                        color: var(--white); 
                    }
                }
            }
        }
    } 
    .header-language-icon {
        .header-language {
            .language-dropdown { 
                background-color: var(--dark-gray);
                a {
                    color: var(--light-gray);
                    &:hover {
                        color:var(--white);
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/* Header light */
.header-light { 
    .navbar-nav {
        .nav-link {
            color: var(--dark-gray); 
        }
    }
    .header-icon {
        .icon { 
            > a {
                color: var(--dark-gray);
                &:hover {
                    color: var(--dark-gray);
                    opacity: 0.6;
                }
            }
            .push-button {
                span {
                    background-color: var(--dark-gray);
                }
            }
            .header-language, .header-cart {
                > a {
                    color: var(--dark-gray);  
                    &:hover {
                        color: var(--dark-gray);
                        opacity: 0.6;
                    } 
                }
            }
        }  
    }
    .menu-item-list {
        .nav-link {
            color: var(--dark-gray);
            &:hover {
                color: var(--dark-gray);
                opacity: 0.6;  
            }
        }
        .sub-menu {
            a {
                color: var(--medium-gray);
                opacity: 1;
                &:hover {
                    color: var(--dark-gray);
                }
            }
            .active {
                > a {
                    color: var(--dark-gray);
                }
            } 
        } 
        .menu-item {
            .menu-toggle {
                &:before, &:after {
                    background-color: var(--dark-gray);
                }
            }
        }
    } 
    .widget-text {
        color:var(--dark-gray);
        a {
            color:var(--dark-gray);
        } 
    }
}

/* Header icon */
.header-icon {
    display: flex;
    align-items: center;
    .icon { 
        position: relative;
        line-height: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        > a {
            padding-left: 16px;
            font-size: 17px;
            color: var(--white);
            &:hover {
                color: var(--white);
                opacity: 0.6;
            } 
        }
        &:first-child {
            a {
                &:first-child {
                    padding-left: 0;
                }  
            } 
        }
        .header-language, .header-cart {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-item-align: stretch;
            -ms-grid-row-align: stretch;
            align-self: stretch;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            > a {
                padding-left: 14px; 
                color: var(--white);  
                font-size: 17px;
                &:hover {
                    color: var(--white);
                    opacity: 0.6;
                } 
            }
        }
    }
    .header-mail {
        padding-right: 35px;
        top: 0;
        .mail {
            font-size: 14px; 
            i {
                vertical-align: middle;
                padding-right: 5px;
            }
        }
    } 
    .header-social-icon { 
        a {
            font-size: 17px;
            padding-left: 20px;
            flex-shrink: 0;
        }
    } 
}

/* Header cart */
.header-cart-icon { 
    .header-cart {
        display: inline-block;  
        .cart-item-list {
            display: none;
            left: auto;
            right: 0;
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
            border-radius: 0; 
            background-color: var(--white); 
            border: 0;
            padding: 0;
            min-width: 250px;
            margin: 0;
            position: absolute;
            z-index: 1;
            top: 100%;
            .cart-item {
                display: flex;
                padding: 17px;
                border-top: 1px solid #ededed;
                position: relative;
                .close {
                    font-size: 16px; 
                    position: absolute;
                    right: 15px;
                    top: 15px;
                    font-weight: 400;
                    color: var(--dark-gray); 
                }
                .product-image {
                    max-width: 50px;
                    width: 30%;
                    display: inline-block;
                }
                .product-detail {
                    padding-left: 15px;
                    width: 70%;
                    text-align: left;
                    display: inline-block;
                    a {
                        color: var(--dark-gray);
                        font-size: 12px;
                        display: inline-block;
                        line-height: 16px;
                        padding: 0;
                    }
                    .item-ammount {
                        display: block;
                        font-size: 11px; 
                        color: var(--medium-gray);
                        line-height: normal;
                    }
                }
                &:first-child {
                    border-top: 0;
                }
            }
            .cart-total {
                background-color: var(--very-light-gray); 
                flex-direction: column!important;
                color:var(--dark-gray); 
                span { 
                    line-height: 20px;
                    display: inline-block;
                }
                .btn {
                    width: 100%;
                    display: block;
                    margin: 8px 0;
                }
            }
        }
        &.open {
            .cart-item-list {
                display: block;
                -webkit-animation: show-animation .5s;
                animation: show-animation .5s;
            }  
        }
    }
    a { 
        position: relative;  
    }
    .cart-count {
        position: absolute;
        top: -7px; 
        right: -11px; 
        width: 17px;
        height: 17px;
        font-weight: 600;
        text-align: center;
        font-size: 10px;
        line-height:18px; 
        background-color: var(--base-color);
        color: var(--white); 
        @include prefix(border-radius, 100%, webkit); 
        @include prefix(transition, 0.5s, webkit); 
    }
} 

/* Header language */
.header-language-icon {
    .header-language {
        display: inline-block; 
        .language-dropdown {
            position: absolute;
            right: 0;
            top: 100%;
            padding: 20px; 
            list-style: none;
            background-color: var(--white);   
            display: none;
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
            -webkit-animation: hide-animation .5s;
            animation: hide-animation .5s;
            z-index: 9;
            text-align: left;
            margin: 0;
            min-width: 140px;
            li { 
                a {
                    color: var(--medium-gray);
                    font-size: 12px;
                    line-height: 28px;
                    padding: 0;
                    .icon-country {
                        width: 16px;
                        height: 16px;
                        display: inline-block;
                        margin: 0 10px 0 5px;
                    }
                    &:hover {
                        color: var(--dark-gray);
                        opacity: 1; 
                    }
                }
            }
        }
        &.open {
            .language-dropdown {
                display: block;
                -webkit-animation: show-animation .5s;
                animation: show-animation .5s;
            } 
        }
    }
}

/* Header widget */
.widget-text {
    color:var(--white);
}
header a.widget-text:hover {
    opacity: .6;
}

/* Header push button */
.header-push-button { 
    padding-left:16px; 
    .push-button { 
        position: relative;
        display: block;
        width:22px; 
        height: 14px; 
        padding:0;
        cursor: pointer;
        @include prefix(transition, 0.3s, webkit); 
        span { 
            background:var(--white); 
            height: 2px; 
            width: 22px; 
            content: ""; 
            display: block; 
            border-radius: 0; 
            position: absolute; 
            left: 0; 
            right: 0; 
            margin-left: auto; 
            margin-right: auto;   
            @include prefix(transform, rotate(0deg), webkit);
            @include prefix(transition, 0.25s, webkit);
            &:nth-child(1) { 
            }
            &:nth-child(2) {
                top: 6px;
            }
            &:nth-child(3) {
                top: 6px;
            }
            &:nth-child(4) { 
                top: 12px; 
                width: 14px;
                left: -8px;
            }
        }
        &:hover {
            opacity: 0.6;
        }
    }
}
.show-menu {
    .header-push-button { 
        .push-button {  
            span {  
                &:nth-child(1) { 
                    top: 7px; 
                    width: 0%; 
                }
                &:nth-child(2) {
                    @include prefix(transform, rotate(45deg), webkit);
                }
                &:nth-child(3) { 
                    @include prefix(transform, rotate(-45deg), webkit);
                }
                &:nth-child(4) { 
                    top: 7px; 
                    width: 0%;  
                }
            }
        }
    }
}
.navbar-modern-inner {
    display: none;
    .navbar-toggler {
        display: none; 
    }
}

/* Header push menu */
.show-menu {
    overflow: hidden;
    .push-menu {
        right: 0; 
        @include box-shadow(0 0 60px rgba(0,0,0,.3));
        .close-menu {
            opacity: 1; 
            @include prefix(transform, scale(1), webkit);
            @include prefix(transition, all 400ms cubic-bezier(0.37, 0, 0.63, 1), webkit);
            @include prefix(transition-delay, 0.5s, webkit);
            visibility: visible;
            cursor: pointer;
        }
        &.push-menu-left {
            right:auto;
            left: 0;
        }
    }
}
.push-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 400px;
    height: 100%;
    z-index: 1031;
    overflow-y: auto;
    @include prefix(transition, 0.5s, webkit);
    &.push-menu-left {
        right:auto;
        left: -100%;
    }
    .left-circle {
        width: 500px !important;
        height: 500px;
        position: absolute;
        left: -160px;
        top: -80px;
        @include prefix(border-radius, 100%, webkit);
        &:before, &:after {
            width: 100%;
            height: 100%;
            border: 1px solid var(--light-medium-gray);
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(10px,5px);
            @include prefix(border-radius, 100%, webkit);
        }
        &:after {
            transform: translate(5px,5px);
        }
    }
    .close-menu {
        height: 40px;
        width: 40px;
        text-align: center;
        position: absolute;
        right: 50px;
        top: 50px; 
        z-index: 9; 
        display: flex;
        border-radius: 100%;
        align-items: center;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        @include prefix(transform, scale(0.8), webkit);
    }
    .push-menu-wrapper {
        height: 100%;
        overflow-y: hidden;
        padding: 20px 0;
        .mCustomScrollBox { 
            display: flex;
        }
        .mCustomScrollBox {
            .mCSB_container {
                &.mCS_y_hidden {
                    &.mCS_no_scrollbar_y {
                        align-self: center;
                    }
                }
            }
        }
        .mCustomScrollBox {
            .mCSB_container {
                width: 100%;
                align-self: flex-start;
            }
        }
        .mCSB_inside {
            >.mCSB_container {
                margin: 0 !important;
                padding: 0 15px !important;
            }
        }
    }
    &.push-menu-style-2 {
        .push-menu-wrapper {
            .mCustomScrollBox {
                .mCSB_container {
                    &.mCS_y_hidden{
                        &.mCS_no_scrollbar_y {
                            align-self: start;
                        }
                    }
                }
            }
        }
    }
    &.push-menu-style-3 {
        .push-menu-wrapper {
            .mCustomScrollBox {
                .mCSB_container {
                    &.mCS_y_hidden{
                        &.mCS_no_scrollbar_y {
                            height: 100%; 
                            display: flex;
                            flex-wrap: wrap;
                            align-content: space-between;
                        }
                    }
                }
            }
        }
    }
}

/* Header center logo */
.center-logo {
    .navbar-nav {
        .nav-link {
            padding: 38px 20px;
        }
    } 
    .menu-logo {
        .d-none {
            -ms-flex-item-align: center;
            -ms-grid-row-align: center;
            align-self: center;
        }
    }
    .navbar-brand {
        position: absolute;
        left: 50%;  
        top: 50%;  
        @include prefix(transform, translate(-50%, -50%), webkit);
        transition: none; 
    }
    .navbar-left, .navbar-right {
        width: 42%;    
    } 
}  

/* Header top logo */
.top-logo { 
    .navbar-brand { 
        padding:22px 0;
    } 
    .navbar-nav {
        .nav-link {
            padding-top:25px !important;
            padding-bottom:25px !important;
        }  
    }
} 

/* Header left menu simple */
.left-sidebar-wrapper {
    padding-left: 300px;
    position: relative;
    min-height: 300px;
    width: 100%;
    .collapse:not(.show) {
        display: block !important;
    }
}
header {
    &.sticky {
        .left-sidebar-nav {
            .navbar-nav {
                .nav-link {
                    padding: 0;
                }
            }
        }
    }
}
.left-sidebar-header { 
    position: absolute;
    left: 0;
    width: 301px;
    top: -100%;
    bottom: 0;
    z-index: 999;
    display: block;
    .menu-item-list {
        .menu-item {
            .nav-link {
                font-size: 16px;
                line-height: 20px; 
            }
            .menu-toggle {
                top: 10px;
            }
            .sub-menu {
                .menu-toggle {
                    top: 0;
                }
            }
        }
    }
} 
.left-sidebar-nav { 
    width: 300px;
    padding: 80px 50px;
    left: 0;
    position: fixed; 
    overflow: hidden;
    display: block;
    height: inherit;  
    @include prefix(transition, 0.3s, webkit);
    .left-menu-bottom { 
        width: 100%; 
        .header-icon {
            .icon {
                a {
                    padding: 0 15px;
                }
            }
        }
    }
    .navbar-brand {
        padding: 0 !important;
    }
    .side-menu {
        margin-top: 10.5rem;
        margin-bottom: 10.5rem;   
        width: 100%; 
    } 
    .navbar-toggler {
        display: none; 
    }
}
.social-text {
    width: 100%;
    display: inline-block;
    color: var(--medium-gray);
    font-size: 13px;
    line-height: 16px; 
}
.connect-me {
    a {
        color: var(--dark-gray); 
        display: table;
        &:hover {
            opacity: 0.6;
        }
    }
    .widget-text {
        color: var(--dark-gray);
        display: table;
    }
}

/* Menu list */
.menu-item-list {
    display: block;
    width: 100%;
    list-style: none;
    padding:0;
    margin-bottom: 0;
    .menu-item {
        position: relative; 
        margin:0;
        ul {
            list-style: none;
            padding: 0;
        }
        .menu-toggle {
            display: block;
            position: absolute;
            right: 0;
            top: 14px;  
            cursor: pointer; 
            z-index: 1;
            width:30px;
            height: 30px;
            line-height: 30px;
            &:before, &:after { 
                content: "";
                display: block;
                position: absolute;
                right: 10px;
                top: 15px;
                height: 1px;
                width: 11px;
                background-color: var(--white);
            } 
            &:after {
                @include prefix(transform, rotate(-90deg), webkit); 
            }
            &.show {
                &:after {
                    @include prefix(transform, rotate(0deg), webkit);
                } 
            }
        }
        .sub-menu { 
            .menu-toggle {
                top: 0;
                &:before, &:after {
                    width: 7px;  
                }
            }
            .active {
                > a {
                    opacity: 1;
                }
            }
        }
    }
    .active {
        .nav-link {
            opacity: 0.6;   
        } 
    }
    .nav-link {
        padding:5px 20px 5px 0;
        font-size: 20px;
        line-height: 28px; 
        display: inline-block;
        width: 100%;
        margin:9px 0;
        color: var(--white);
        @include prefix(transition, 0.3s, webkit); 
        &:hover {
            color: var(--white);
            opacity: 0.6; 
        }
    }
    .sub-menu { 
        padding: 0;
        display: block; 
        height: 0;
        overflow: hidden; 
        list-style: none;
        margin:0; 
        position: relative !important;
        transform: none !important;
        background-color: transparent; 
        border: 0;
        li { 
            position: relative;
            margin:0;
            padding:5px 0;
            .sub-menu {
                margin: 0;
                padding-left: 15px; 
                li {
                    padding: 10px 0 0;
                    a {
                        padding: 0;
                        i {
                            margin-right: 10px; 
                        } 
                    }
                    &:first-child {
                        margin-top: 10px;
                    }
                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
            &:last-child {
                margin-bottom: 0; 
                .sub-menu {
                    li {
                        &:last-child {
                            margin-bottom: 0;
                        } 
                    }
                }
            }
        }
        a {
            font-size: 15px;
            line-height: 22px; 
            display: block;
            color: var(--light-gray);
            margin: 0;
            padding-right: 20px; 
            &:hover {
                color: var(--white);
            }
            .label {
                display: inline-block;
                padding:5px 10px;
                font-size: 10px;
                line-height: initial;
                margin-left: 8px;
                margin-top: 0px;
                vertical-align: middle;
                font-weight: 700;
                text-transform: uppercase;
                white-space: nowrap;
            }
        }
        &.show {
            height: auto;
            overflow: visible;
        } 
    }   
}

/* Header left menu modern */
.left-modern-menu {
    padding-left: 75px;
    .collapse:not(.show) {
        display: block !important; 
    }
    .left-modern-header {
        .navbar-brand {
            position: relative;
            z-index: 9;
            width: 100%;
            padding: 0 !important;
            text-align: center;
            img {
                max-height: 45px;
            }
        }
        .modern-sidebar-nav { 
            position: fixed;
            height: 100%;
            width: 380px; 
            overflow-y: auto;  
            top: 0; 
            left: -400px; 
            transition-duration: .3s; 
            z-index: 9;
            &:hover {
                .side-menu-hover-background-color {
                    opacity: 1;
                } 
            }
        }  
        .modern-side-menu { 
            padding:75px 40px;
            height: 100%; 
            overflow-y:hidden;  
            .mCustomScrollBox {   
                display: flex; 
                width: 100%;
                .mCSB_container {
                    align-self: flex-start;
                    width: 100%;
                    margin: 0; 
                    padding: 0 30px;  
                    &.mCS_no_scrollbar_y {
                        align-self: center;   
                    }
                } 
            } 
        }
        &.show { 
            .modern-sidebar-nav {
                left: 75px; 
                box-shadow: 0 0 150px rgba(0,0,0,.2);
            }
            .navbar-toggler-line {  
                &:nth-child(1) { 
                    top: 7px; 
                    width: 0%; 
                }
                &:nth-child(2) {
                    @include prefix(transform, rotate(45deg), webkit);
                }
                &:nth-child(3) { 
                    @include prefix(transform, rotate(-45deg), webkit);
                }
                &:nth-child(4) { 
                    top: 7px; 
                    width: 0%;  
                }
            } 
        }
    } 
}
.side-menu-hover-background-color {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; 
    @include prefix(transition-duration, 0.6s, webkit);
}

/* Header left menu modern dark */
.header-dark {
    .social-text {
        color: var(--white);
    }
    .connect-me {
        .widget-text {
            color: var(--white); 
        }
        a {
            color: var(--white);
            &:hover {
                opacity: 0.6;
            }
        }
    }
    &.left-modern-sidebar {
        .navbar-toggler-line {
            background-color: var(--white); 
        }
    }
}
.vertical-title {
    display: flex;
    height: 100%;
    flex: 1; 
    justify-content: flex-end;
    flex-direction: column;  
    @include prefix(transform, translateY(-75px), webkit);
    .title {  
        @include prefix(transform, rotate(-90deg), webkit);
        white-space: nowrap; 
        text-transform: uppercase; 
    }
} 
.menu-list-wrapper {
    overflow: auto;
    height: calc(100vh - 150px);
    padding:50px 0; 
    .mCustomScrollBox {   
        display: flex; 
        width: 100%;
        .mCSB_container {
            align-self: flex-start;
            width: 100%;
            margin: 0;
            &.mCS_no_scrollbar_y {
                align-self: center;   
            }
        } 
    }
} 
.left-modern-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    width: 75px;
    padding: 35px 0;
    .toggle-icon {  
        position: absolute;
        top: 50%;
        left: 50%;   
        z-index: 9;
        @include prefix(transform, translate(-12px,-50%), webkit);
        .navbar-toggler {
            margin: 0; 
            position: relative; 
            margin: 0 auto; 
            z-index: 99; 
            cursor: pointer; 
        } 
    }
}

/* Hamburger menu */
.hamburger-nav {
    width: 50%;
    @include prefix(transition, 0.5s, webkit); 
    .menu-item-list {
        margin-bottom:0; 
        padding: 0 30px;
        .menu-item {
            margin:0; 
        }   
    }
} 
.hamburger-menu { 
    .header-icon {
        position: absolute;
        left: 0;
        bottom:-50px;
    }
}

/* Hamburger menu half */
.show-menu { 
    .hamburger-menu-half {
        .hamburger-logo {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.20s;
        }
        .menu-address {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.40s;
        }
        .menu-text {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.80s;
        }
        .elements-social {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.80s;
        }
        .menu-item-list {
            .menu-item {
                transform: translateY(0px); 
                -webkit-transform: translateY(0px);
                opacity: 1;
                &:nth-child(1) {  
                    transition-delay: 0.27s;
                }
                &:nth-child(2) {  
                    transition-delay: 0.34s;
                }
                &:nth-child(3) {  
                    transition-delay: 0.41s;
                }
                &:nth-child(4) {  
                    transition-delay: 0.48s;
                }
                &:nth-child(5) {  
                    transition-delay: 0.55s;
                }
                &:nth-child(6) {  
                    transition-delay: 0.62s;
                }
                &:nth-child(7) {  
                    transition-delay: 0.69s;
                }
                &:nth-child(8) {  
                    transition-delay: 0.76s;
                }
                &:nth-child(9) {  
                    transition-delay: 0.83s;
                }
                &:nth-child(10) {  
                    transition-delay: 0.9s;
                }
                &:nth-child(11) {  
                    transition-delay: 0.97s;
                }
                &:nth-child(12) {  
                    transition-delay: 1.04s;
                }
                &:nth-child(13) {  
                    transition-delay: 1.11s;
                }
                &:nth-child(14) {  
                    transition-delay: 1.18s;
                }
                &:nth-child(15) {  
                    transition-delay: 1.25s;
                }
            }
        }
    }
}
.hamburger-menu-half {
    .hamburger-logo {
        transform: translateY(30px); 
        opacity: 0;  
        transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    } 
    .menu-address {
        transform: translateY(30px); 
        opacity: 0;  
        transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    } 
    .elements-social {
        transform: translateY(30px); 
        opacity: 0;  
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    } 
    .menu-item-list { 
        .menu-item {
            transform: translateY(30px); 
            opacity: 0;  
            transition: transform 0.8s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.4s cubic-bezier(0.8, 0.01, 0.165, 0.99); 
            &:nth-child(1) {  
                transition-delay: 0.49s;
            }
            &:nth-child(2) {  
                transition-delay: 0.42s;
            }
            &:nth-child(3) {  
                transition-delay: 0.35s;
            }
            &:nth-child(4) {  
                transition-delay: 0.28s;
            }
            &:nth-child(5) {  
                transition-delay: 0.21s;
            }
            &:nth-child(6) {  
                transition-delay: 0.14s;
            }
            &:nth-child(7) {  
                transition-delay: 0.07s;
            }
            &:nth-child(8) {  
                transition-delay: 0s;
            }
            &:nth-child(9) {  
                transition-delay: -0.07s;
            }
            &:nth-child(10) {  
                transition-delay: -0.14s;
            }
            &:nth-child(11) {  
                transition-delay: -0.21s;
            }
            &:nth-child(12) {  
                transition-delay: -0.28s;
            }
            &:nth-child(13) {  
                transition-delay: -0.35s;
            }
            &:nth-child(14) {  
                transition-delay: -0.42s;
            }
            &:nth-child(15) {  
                transition-delay: -0.49s;
            }
        }  
    }
}

/* Hamburger menu simple */
.show-menu { 
    .hamburger-menu-simple {
        opacity:1;
        visibility: visible; 
        top: 0px;
        .hamburger-menu {
            opacity: 1; 
        }
        .hamburger-logo {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.20s;
        }
        .menu-address {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.40s;
        }
        .menu-text {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.80s;
        }
        .elements-social {
            transform: translateY(0px); 
            opacity: 1;   
            transition-delay: 0.80s;
        }
        .menu-item-list {
            .menu-item {
                transform: scale(1) translateY(0px); 
                -webkit-transform: scale(1) translateY(0px);
                opacity: 1;
                &:nth-child(1) {  
                    transition-delay: 0.27s;
                }
                &:nth-child(2) {  
                    transition-delay: 0.34s;
                }
                &:nth-child(3) {  
                    transition-delay: 0.41s;
                }
                &:nth-child(4) {  
                    transition-delay: 0.48s;
                }
                &:nth-child(5) {  
                    transition-delay: 0.55s;
                }
                &:nth-child(6) {  
                    transition-delay: 0.62s;
                }
                &:nth-child(7) {  
                    transition-delay: 0.69s;
                }
                &:nth-child(8) {  
                    transition-delay: 0.76s;
                }
                &:nth-child(9) {  
                    transition-delay: 0.83s;
                }
                &:nth-child(10) {  
                    transition-delay: 0.9s;
                }
                &:nth-child(11) {  
                    transition-delay: 0.97s;
                }
                &:nth-child(12) {  
                    transition-delay: 1.04s;
                }
                &:nth-child(13) {  
                    transition-delay: 1.11s;
                }
                &:nth-child(14) {  
                    transition-delay: 1.18s;
                }
                &:nth-child(15) {  
                    transition-delay: 1.25s;
                }
            }
        }
    } 
} 
.hamburger-menu-simple {
    width: 100%;
    right:0; 
    opacity:1; 
    visibility: collapse; 
    height: 100vh; 
    max-height: 100dvh;
    top: -100%;
    @include prefix(transition, 0.3s, webkit);
    .navbar-brand {
        position: absolute;
        top: 100px;
        left: 0;
        right: 0;
    }
    .hamburger-menu {
        padding: 30px 0;
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99); 
        transition-delay: 0.40s;
        opacity:0;
        .header-icon {
            bottom: 100px;
        }
    }
    .hamburger-logo {
        transform: translateY(-50px); 
        opacity: 0;  
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }
    .menu-address {
        transform: translateY(-50px); 
        opacity: 0;  
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }
    .menu-text {
        transform: translateY(-50px); 
        opacity: 0;  
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    }
    .elements-social {
        transform: translateY(-50px); 
        opacity: 0;  
        transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    } 
    .menu-item-list { 
        .menu-item {
            transform: scale(1.15) translateY(-30px); 
            opacity: 0;  
            transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99); 
            &:nth-child(1) {  
                transition-delay: 0.49s;
            }
            &:nth-child(2) {  
                transition-delay: 0.42s;
            }
            &:nth-child(3) {  
                transition-delay: 0.35s;
            }
            &:nth-child(4) {  
                transition-delay: 0.28s;
            }
            &:nth-child(5) {  
                transition-delay: 0.21s;
            }
            &:nth-child(6) {  
                transition-delay: 0.14s;
            }
            &:nth-child(7) {  
                transition-delay: 0.07s;
            }
            &:nth-child(8) {  
                transition-delay: 0s;
            }
            &:nth-child(9) {  
                transition-delay: -0.07s;
            }
            &:nth-child(10) {  
                transition-delay: -0.14s;
            }
            &:nth-child(11) {  
                transition-delay: -0.21s;
            }
            &:nth-child(12) {  
                transition-delay: -0.28s;
            }
            &:nth-child(13) {  
                transition-delay: -0.35s;
            }
            &:nth-child(14) {  
                transition-delay: -0.42s;
            }
            &:nth-child(15) {  
                transition-delay: -0.49s;
            }
        }
        .nav-link {
            font-size: 20px; 
            width: auto;
            position: relative;  
            &:hover {
                opacity:0.6;
            }
        } 
    } 
}
.hamburger-logo {
    img {
        max-height: 45px;
    }
}

/* Right push menu */
.right-push-menu {
    .menu-item-list {
        .menu-item {
            .toggle {
                top: 18px;
            }
        }
        .sub-menu {
            li {
                .toggle {
                    top: 4px;
                }
            }
        }
        .nav-link {
            font-size: 1.5rem;
            line-height: 2.5rem;
        }
    }
    .left-menu-bottom {
        margin-top: 30px;
        .header-icon {
            position: relative;
            bottom: 0;
        }
    }
}

/* Header search popup */
.show-search-popup {
    overflow: hidden;
    header {
        .navbar {
            z-index: 110;
        }
    }
    .search-form-wrapper {
        opacity: 1;
        visibility: visible; 
        cursor: url(../images/mfg-close.png),auto;
        .search-form { 
            height: 50%; 
            cursor: default; 
            visibility: visible;
            opacity: 1;
        }
    }
}
.search-form-wrapper {
    background: rgba(35,35,35,.9); 
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden !important; 
    display: block !important;
    height: 100vh;
    position: fixed; 
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    @include prefix(transition, 0.2s, webkit);
    .search-form { 
        height: 0;
        background-color: var(--white);
        width: 100%;
        left: 0;
        top: 0; 
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        @include prefix(transition, 0.6s, webkit);
        .search-input {
            outline: none;
            border: 0;
            border-bottom: 1px solid rgba(23,23,23,.2);
            color: var(--dark-gray);
            font-size: 18px;
            padding: 20px 0;
            background: transparent;
            line-height: 2.5rem;
            @include prefix(border-radius, 0px, webkit);
            height: 80px;
            width: 100%;
            &::-moz-placeholder  {
                color: var(--light-gray);
                opacity: 1;
            }
            &::-webkit-input-placeholder {
                color: var(--light-gray);
            }
        }
        .search-button { 
            color: var(--dark-gray); 
            bottom: 24px; 
            position: absolute; 
            font-size:25px; 
            right: 0;
            line-height: normal;
            outline: none;
            cursor: pointer;
            z-index: 0;
            margin-right: 0;
            border: none;
            background: none; 
        }  
        &:hover {
            color:var(--light-gray);
        }
    }
    .search-close { 
        color: var(--black); 
        position: fixed; 
        top: 30px; 
        right: 35px; 
        overflow: visible; 
        cursor: pointer; 
        background: transparent; 
        border: 0; 
        -webkit-appearance: none; 
        display: block; 
        outline: none; 
        padding: 0; 
        z-index: 1046;
        box-shadow: none; 
        font-size: 40px; 
        font-weight: 300; 
        width: 44px; 
        height: 44px; 
        line-height: 44px; 
    } 
    .search-form-box { 
        position: relative; 
        width: 45%;
    }
}

/* Custom hide animation */
@-webkit-keyframes hide-animation {
    0% { 
        opacity: 1; }
    to { 
        opacity: 0; } 
}

/* Custom slide animation */
@-webkit-keyframes show-animation {
    0% { 
        opacity: 0; }
    to { 
        opacity: 1; } 
}
button {
    &:focus { 
        outline: none;
    }   
}  

/* Header toggle button  */
.navbar-toggler { 
    font-size: 24px;
    width: 22px; 
    height: 14px; 
    display: inline-block; 
    position: relative; 
    padding: 0; 
    margin: 0;
    border: none; 
    vertical-align: middle; 
    border-radius: 0; 
    @include prefix(transition, 0.3s, webkit);
    &:focus {
        box-shadow: none; 
    }
}
.navbar-toggler-line { 
    background:var(--dark-gray); 
    height: 2px; 
    width: 22px; 
    content: ""; 
    display: block; 
    border-radius: 0; 
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;   
    @include prefix(transform, rotate(0deg), webkit);
    @include prefix(transition, 0.25s, webkit);
    &:nth-child(1) { 
        top: 0px; 
        width: 14px; 
    }
    &:nth-child(2) {
        top: 6px;
    }
    &:nth-child(3) {
        top: 6px;
    }
    &:nth-child(4) { 
        top: 12px; 
        width: 14px; 
    }
}
.navbar-collapse-show {
    .navbar-toggler-line {  
        &:nth-child(1) { 
            top: 7px; 
            width: 0%; 
        }
        &:nth-child(2) {
            @include prefix(transform, rotate(45deg), webkit);
        }
        &:nth-child(3) { 
            @include prefix(transform, rotate(-45deg), webkit);
        }
        &:nth-child(4) { 
            top: 7px; 
            width: 0%;  
        }
    }
}
.navbar-modern-inner {
    display: none;
    .navbar-toggler {
        display: none; 
    }
}
.header-with-topbar {
    + .top-space-margin {
        margin-top: 136px;
    }
    + .top-space-padding {
        padding-top: 136px;
    }
} 
.navbar-modern-inner, .navbar-full-screen-menu-inner {
    display: none;
}
.top-space-margin {
    margin-top: 96px;
}
.top-space-padding {
    padding-top: 96px;
}

/* Landing page header */
.header-demo {
    &.navbar {
        .navbar-nav {
            .premium-element{
                padding: 35px 40px;
                border-radius: 13px;
                a {
                    color: #585858;
                    padding-bottom: 8px;
                }
                .sub-title {
                    font-size: 18px;
                }
            }
            .nav-item {
                &:first-child {
                    .dropdown-menu {
                        a {
                            color: var(--dark-gray);
                        }
                    }
                }
            }
            .nav-link {
                font-weight:600;
            }
            .dropdown{
                .dropdown-menu{
                    a{
                        font-weight:500;
                    }
                }
            }
        }
        &.header-dark {
            .navbar-nav {
                .nav-item {
                    &:first-child {
                        .dropdown-menu {
                            a {
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
    }
}