/* Grid */ 
.grid {
    list-style: none;
    padding: 0;
    margin: 0
}

/* Grid column and gutter spacing */ 
.grid {
    li {
        img {
            width: 100%;
        }
    }
    .grid-sizer {
        padding: 0 !important;
        margin: 0 !important;
    }
    &.gutter-very-small {
        li {
            padding: 3px 3px;
        }
    }
    &.gutter-small {
        li {
            padding: 5px 5px;
        }
    }
    &.gutter-medium {
        li {
            padding: 7px 7px;
        }
    }
    &.gutter-large {
        li {
            padding: 10px 10px;
        }
    }
    &.gutter-extra-large {
        li{
            padding: 15px;
        }
    }
    &.gutter-double-extra-large {
        li{
            padding: 20px;
        }
    }
    &.gutter-triple-extra-large {
        li{
            padding: 30px;
        }
    }
    &.grid-6col {
        li {
            width: 16.67%;
            &.grid-item-double {
                width: 33.33%;
            }
            &.grid-item-single {
                width: 100%;
            }
        }
    }
    &.grid-5col {
        li {
            width: 20%;
            &.grid-item-double {
                width: 40%;
            }
            &.grid-item-single {
                width: 100%;
            }
        }
    }
    &.grid-4col {
        li {
            width: 25%;
            &.grid-item-double {
                width: 50%;
            }
            &.grid-item-single {
                width: 100%;
            }
        }
    }
    &.grid-3col {
        li {
            width: 33.33%;
            &.grid-item-double {
                width: 66.67%;
            }
            &.grid-item-single {
                width: 100%;
            }
        }
    }
    &.grid-2col {
        li {
            width: 50%;
            &.grid-item-double {
                width: 100%;
            }
            &.grid-item-single {
                width: 100%;
            }
        }
    }
}
.grid {
    .gutter-large {
        margin-left: 10px;
        margin-right:10px;
        margin: 0 10px;
    }
}